<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center gpp"
      @click="visible = !visible"
      :class="{ opened: visible }"
    >
      <div>
        
        <b-checkbox   v-model="gp.sel" @change="selectGroup(gp)"></b-checkbox>
      </div>
      <div class="flex-1-1" style="flex: 1">
        {{ gp.grupoNome }} -
        <span v-if="gp.grupoNome && gp.grupoNome.includes('Dia')">
          {{ (gp.nomeExibicao || `Não Informado`) | moment("DD/MM - ddd") }}
        </span>
        <span v-else-if="gp.grupoNome && gp.grupoNome.includes('Mes')">
          {{ (gp.nomeExibicao || `Não Informado`) | moment("MM/YYYY") }}
        </span>
        <span v-else>
          {{ gp.nomeExibicao || `Não Informado` }}
        </span>
      </div>

      <div>
        {{ gp.valor_contabilizado | currency }}
        <b-icon-chevron-down font-scale="1.2" v-if="!visible" />
        <b-icon-chevron-up font-scale="1.2" v-if="visible" />
      </div>
    </div>

    <b-card-text>
      <b-collapse :id="`collapse-${name}`" v-model="visible" class="mt-2">
        <div v-if="gp.lista.length > 0 && gp.lista[0].lista">
          <div v-for="(gpa, idx) in gp.lista" :key="idx" class="pl-4">
            <RelatorioMovimentacaoFinanceiraGroup :gp="gpa" @selectGroup="selectGroup" />
          </div>
        </div>
        <div v-else>
          <RelatorioMovimentacaoFinanceiraDetalhes :lista="gp.lista" />
        </div>
      </b-collapse>
    </b-card-text>
  </div>
</template>

<script>
export default {
  components: {
    RelatorioMovimentacaoFinanceiraDetalhes: () =>
      import("./RelatorioMovimentacaoFinanceiraDetalhes.vue"),
    RelatorioMovimentacaoFinanceiraGroup: () =>
      import("./RelatorioMovimentacaFinanceiraGroup.vue"),
  },
  props: {
    gp: Object,
  },
  mounted() {
    console.log(`gp`, this.gp);
  },
  data() {
    return {
      visible: this.gp.lista.length > 0 && this.gp.lista[0].lista? true : false,
      name: "RelatorioMovimentacaoFinanceiraGroup" + Math.random(),
    };
  },
  watch: {
    gp: {
      handler() {
        this.visible = this.gp.lista.length > 0 && this.gp.lista[0].lista? true : false;
        this.$emit("sel", this.gp.sel);
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    selectGroup(gp) {
      this.$emit("selectGroup", gp);
    },
  },
};
</script>

<style lang="scss" scoped>
.gpp {
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background-color: #ccc;
  }
}
.opened {
  background-color: #f9f9f9;
}
</style>
